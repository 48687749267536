import {BehaviorSubject, Subject} from "rxjs";

export class PaymentService{
  private paymentKey =  new BehaviorSubject<string>('')

  setPayment(paymentKey: string) {
    this.paymentKey.next(paymentKey);
  }

  getPayment() {
    return this.paymentKey.asObservable();
  }
}
