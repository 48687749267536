import {Component, EventEmitter, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {injectStripe, NgxStripeModule, StripeService} from "ngx-stripe";
import {Payments} from "../models/payments";
import {ActivatedRoute, Router} from "@angular/router";
import {PaymentIntentResult} from "@stripe/stripe-js";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-redirect',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './redirect.component.html',
  styleUrl: './redirect.component.scss'
})
export class RedirectComponent {
  stripe = injectStripe(environment.stripeKey);
  @Output() onRedirect = new EventEmitter<any>
  constructor(private route: ActivatedRoute, private router: Router) {

  }
  ngOnInit() {
    this.getUrl();
    const clientSecret: any = this.route.snapshot.queryParams['payment_intent_client_secret'];

    this.stripe.retrievePaymentIntent(clientSecret).subscribe((res: PaymentIntentResult) => {
      console.log('retrieve payment', res);
      if(res.paymentIntent?.status === 'succeeded'){
        console.log('payment succeeded');
        this.onRedirect.emit(true);
       // this.router.navigateByUrl(this.getUrl() );
      } else {
       alert('Payment failed');
       window.location.href = origin + '/' + this.getUrl();
      }
    });
  }

  getUrl(){
     return this.route.snapshot.url.join('/');
  }
}
