import { Routes } from '@angular/router';
import {AppComponent} from "./app.component";
import {PersonalDetailsComponent} from "./personal-details/personal-details.component";
import {PaymentComponent} from "./tests/payment-test/payment.component";

export const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children:[
      {
        path: 'test',
        component: PaymentComponent
      },
    /*  {
        path: '',
        redirectTo: 'program-code',
        pathMatch: 'full'
      },
      {
        path: 'program-code',
        component: PersonalDetailsComponent
      },*/
      {
        path: '**',
        component: PersonalDetailsComponent
      }
    ]
  },

];
