import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import {environment} from "../../../environments/environment";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-term-conditions',
  standalone: true,
  imports: [
    HttpClientModule, // Import HttpClientModule here
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose
  ],
  templateUrl: './term-conditions.component.html',
  styleUrls: ['./term-conditions.component.scss']
})
export class TermConditionsComponent implements OnInit {

  agreementText: SafeHtml = '';

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.programCode) {
      this.loadAgreementText(this.data.programCode);
    }
  }

  loadAgreementText(programCode: string) {
    const url = `${environment.shopUrl}/products/${programCode}/agreement`;
    this.http.get(url).subscribe((result: any) => {
      this.agreementText = this.sanitizer.bypassSecurityTrustHtml(result.data.agreementText);
    });
  }
}

