

<form #paymentFormElement="ngForm"  id="payment-form" (ngSubmit)="onPaymentSubmit()"  [formGroup]="stripeTest" >
  <div id="payment-element">
    <!-- Elements will create form elements here -->
  </div>
  <div id="error-message">
    <!-- Display error message to your customers here -->
  </div>
</form>
